.metismenu {
  margin: 0;
  li {
    display: block;
    width: 100%;
  }

  .mm-collapse {
    display: none;

    &:not(.mm-show) {
      display: none;
    }

    &.mm-show {
      display: block;
    }
  }
}

.sidebar-menu {
  width: $sidebar-width;
  z-index: $zindex-1001 !important;
  background: $sidebar-bg;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  border-right: 1px solid $neutral-color-100;
  transition: all 0.4s ease-in-out;
}

.main-content {
  background-color: $neutral-color-50;
  padding-left: $sidebar-width;
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  .content {
    padding: 0 15px 10px 15px;
    margin-top: $header-height;
  }
}

#sidebar-menu {
  padding: 10px 0 80px 0;

  .sub-menu-expanded {
    > .has-arrow {
      &:after {
        content: '\F0140';
      }
    }
  }

  .has-arrow {
    &:after {
      content: '\F0142';
      font-family: 'Material Design Icons';
      display: block;
      float: right;
      transition: transform 0.2s;
      font-size: 1rem;
    }
  }

  ul {
    li {
      position: relative;
      white-space: nowrap;
      padding: 0px 8px 8px;
      a {
        display: block;
        font-weight: 500;
        padding: 16px;
        color: $neutral-color-800;
        position: relative;
        font-size: 14px;
        border-radius: 8px;

        svg {
          transition: transform 0.4s ease-in-out;
        }

        span {
          vertical-align: -2px;
        }

        &:hover {
          background-color: $primary-color-50 !important;
          color: $sidebar-menu-item-active-color !important;

          span {
            color: $sidebar-menu-item-active-color !important;
          }
        }
      }

      .badge {
        margin-top: 5px;
      }

      ul.sub-menu {
        padding: 0;
        transition: all 0.4s;

        li {
          a {
            padding: 16px;
            font-size: 14px;
            color: $sidebar-menu-sub-item-color;
            background-color: transparent;
            transition: all 0.4s;

            & > * {
              transition: all 0.4s;
            }

            &:hover {
              background-color: $primary-color-50 !important;
              color: $sidebar-menu-item-active-color !important;

              span {
                background-color: $sidebar-menu-item-active-color !important;
              }
            }
          }

          ul.sub-menu {
            padding: 0;
            transition: all 0.4s;
            li {
              a {
                padding: 0.4rem 1.5rem 0.4rem 4.5rem;
                font-size: 13.5px;
              }
            }
          }
        }
      }
    }
  }
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 0.05em;
  pointer-events: none;
  cursor: default;
  font-size: 10px;
  text-transform: uppercase;
  color: $sidebar-menu-item-icon-color;
  font-weight: $font-weight-semibold;
}

.mm-active {
  color: $sidebar-menu-item-active-color !important;

  > a {
    color: $sidebar-menu-item-active-color !important;
    background-color: $primary-color-50 !important;
  }
  .active {
    background-color: $primary-color-50 !important;
    color: $sidebar-menu-item-active-color !important;
    pointer-events: none;

    span {
      background-color: $sidebar-menu-item-active-color !important;
    }
  }
}

@include media-breakpoint-down(lg) {
  .sidebar-menu {
    display: none;
  }

  .main-content {
    padding-left: 0 !important;
  }

  .vertical-collapsed {
    .sidebar-menu {
      display: block;
    }
  }
}

// Enlarge menu
.vertical-collapsed {
  .main-content {
    padding-left: $sidebar-collapsed-width;
  }

  // Side menu
  .sidebar-menu {
    position: absolute;
    width: $sidebar-collapsed-width !important;
    z-index: $zindex-5;

    .simplebar-mask,
    .simplebar-content-wrapper {
      overflow: visible !important;
    }

    .simplebar-scrollbar {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
      .menu-title,
      .badge,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      a {
        svg {
          transform: scale(1.2);
          margin-left: 6px;
        }
      }

      .has-arrow {
        &:after {
          display: none;
        }
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          &:hover {
            background-color: transparent;
          }

          > a {
            padding: 15px 20px;
            min-height: 55px;
            transition: all 0.4s ease-in-out;

            &:hover,
            &:active,
            &:focus {
              color: $sidebar-menu-item-hover-color;
            }

            span {
              padding-left: 36px;
              transition: all 0.4s ease-in-out;
            }
          }

          // SBOX: control the sub-menu
          @mixin manage-sub-menu {
            > a {
              position: relative;
              width: calc(190px + #{$sidebar-collapsed-width});
              color: $primary;
              background-color: $primary-color-50;
              transition: none;

              i {
                color: $primary;
              }

              span {
                display: inline;
              }
            }

            > ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 196px;
              height: auto !important;
              padding: 8px;
              margin: 0;
              box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
              }

              a {
                display: block;
                box-shadow: none;
                padding: 12px 20px;
                position: relative;
                margin: auto;
                z-index: $zindex-6;
                color: $sidebar-menu-sub-item-color;

                &:hover {
                  color: $sidebar-menu-item-hover-color;
                }
              }
            }
          }

          // Use hover to show hide sub-menu if not touch device
          @media (hover: hover) {
            &:hover {
              @include manage-sub-menu;
            }
          }

          // If touch device, use sub-menu-expanded class to show hide sub-menu
          @media (hover: none) {
            &.sub-menu-expanded {
              @include manage-sub-menu;
            }
          }
        }

        ul {
          padding: 5px 0;
          z-index: $zindex-9999;
          display: none;
          background-color: $sidebar-bg;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $gray-100;
            }
          }
        }
      }
    }
  }
}
