@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.header-assistant {
  &__welcome {
    background: var(--Gradient, linear-gradient(180deg, #1d4ed7 0%, #a4f2fe 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__action-icon {
    svg {
      width: 20px;

      &:focus {
        outline: none;
      }
    }

    .divider {
      width: 1px;
      height: 18px;
      background-color: $neutral-color-200;
    }

    .tooltip-icon {
      color: $neutral-color-400;

      &:hover {
        background: $neutral-color-100;
        border-radius: 4px;
        color: $primary-color-500;
      }
    }
  }
}
