@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'assets/scss/sbox/custom_mixins';

.brainstorming-content {
  .guide {
    border-radius: 12px;
    padding: 12px;
    width: fit-content;
  }

  .back-icon {
    color: $neutral-color-500;

    &:hover {
      color: $primary-color-500;
    }
  }

  .tab-import {
    border-bottom: 2px solid $neutral-color-100;

    .selected-tab {
      position: relative;
      top: 2px;
      border-bottom: 2px solid $primary-color-500;
    }
  }

  .import-file-section {
    border-radius: 12px;

    .drag-drop {
      background-color: $neutral-color-50;
      border-radius: 8px;
      border: 1px dashed $primary-color-100;

      &:hover {
        background-color: $primary-color-50;
        box-shadow:
          0px 1px 3px 0px rgba(16, 24, 40, 0.1),
          0px 1px 2px -1px rgba(16, 24, 40, 0.1);
      }
    }

    .list-file {
      padding: 6px 12px;
      background-color: $white;
      border-radius: 8px;

      .file-name {
        @include evokio-text-truncate(1);
      }

      .trash-icon {
        color: $neutral-color-500;

        &:hover {
          color: $primary-color-500;
        }
      }
    }

    .search-wrapper {
      padding: 8px 12px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid $neutral-color-200;
      background: $white;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    .search-input {
      border: none;

      &:focus,
      &:focus-visible {
        outline: none;
      }
    }

    .area-input {
      padding: 8px 12px;
      border-radius: 10px;
      border: 1px solid $neutral-color-200;
      background: $white;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      min-height: 60px;
      max-height: 160px;
      resize: none;

      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
  }
}
