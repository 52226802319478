@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.btn-pagination {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid $neutral-color-100;
  background-color: $white;

  color: $neutral-color-900;

  transition:
    background-color 0.4s ease-out,
    color 0.4s ease-out;

  &.active {
    background-color: $primary-color-500;

    color: $white;
  }

  &:disabled {
    background-color: $neutral-color-100;
  }

  &.mobile {
    width: 60px;
    height: 40px;
    padding: 10px;
  }
}
