@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.welcome {
  &-wrapper {
    background-image: url('~/src/assets/images/auth/change-pw-mobile.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__title {
    font-size: 24px;

    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.48px;
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }

  &__input {
    width: 100%;

    & > input {
      width: 100%;
      border: 0;
      border-bottom: 1px solid $neutral-color-200;
      display: flex;
      height: 44px;
      padding: 10px 0px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      box-shadow: none;
      outline: none;
      text-align: center;

      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.4px;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $neutral-color-400;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $neutral-color-400;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $neutral-color-400;
    }
  }

  @include media-breakpoint-up(sm) {
    &-wrapper {
      background-image: url('~/src/assets/images/auth/change-pw-desktop.jpg');
    }
  }

  @include media-breakpoint-up(md) {
    &__title {
      font-size: 40px;

      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.8px;
    }

    &__input {
      width: 50%;
    }
  }
}

.btn-submit {
  padding: 10px 14px;

  gap: 8px;

  border-radius: 6px;
  border: 1px solid $primary-color-500;

  color: $primary-color-500 !important;

  &:hover {
    background-color: $neutral-color-200 !important;
  }

  &:disabled {
    background-color: white !important;
    pointer-events: auto !important;
    cursor: not-allowed !important;
    color: $primary-color-200 !important;
  }
}
