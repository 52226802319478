@import 'assets/scss/variables';

.nav-tabs {
  .nav-item {
    margin-right: $spacer * 2.5;
  }
  .nav-link {
    border: none;
    border-bottom: solid 2px transparent;
  }
}
