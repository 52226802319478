@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.ai-assistant-btn {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  border: 2px solid #3460dc;
  background: $neutral-color-50;
  box-shadow:
    0px 5px 8px 0px rgba(52, 96, 220, 0.25),
    0px 0px 0px 4px rgba(52, 96, 220, 0.15);

  &:hover {
    background: linear-gradient(90deg, #3460dc 0%, #a4cafe 100%);
    border: transparent;
  }
}
