@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.form-group {
  min-height: 40px;

  .form-container {
    padding: 8px 12px;
    border-radius: 12px;
    border: 1px solid $gray-color-200;
    box-shadow: 0px 2px 15px 2px rgba(29, 78, 216, 0.1);

    textarea {
      flex: 1;
      border: none;
      outline: none;
      resize: none;
      margin-top: 2px;
      font-size: 14px;
      padding: 6px 4px 0 0;
      min-height: 36px;
    }
  }
}
