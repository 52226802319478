@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.move-folder-wrapper {
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 4px 6px -4px rgba(16, 24, 40, 0.1),
    0px 10px 15px -3px rgba(16, 24, 40, 0.1);

  &__existing {
    border-radius: 6px;
    border: 1px solid $gray-color-200;
    background: $white;
    color: $neutral-color-500;
  }

  &__icon {
    background: $shades-color-white;

    &:hover {
      background-color: $primary-color-50;
    }
  }
  .folder-tree-item {
    &.edit {
      box-shadow: 0px 0px 0px 4px #e1e1fe;
      border: 1px solid $primary-color-200;
      border-radius: 6px;
    }
    input {
      border: none;
      height: 40px;
      border-radius: 6px;
      border: 1px solid $neutral-color-200;
      caret-color: $primary-color-500;
      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
  }
}
