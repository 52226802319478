@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.btn-outline-primary {
  background-color: $white;

  @include button-outline-variant($primary, $primary, $primary-color-50, $primary, $primary);
}

.btn-neutral {
  height: 40px;
  border-radius: 6px;
  border: 1px solid $neutral-color-200;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  outline: none;

  font-weight: $font-weight-normal;

  @include button-variant($white, $neutral-color-200, $neutral-color-900, $primary-color-50);

  &:disabled,
  &.disabled {
    color: $neutral-color-400;
    background-color: $neutral-color-50;
  }
}

.btn-outline-neutral-500 {
  background-color: $white;

  @include button-outline-variant(
    $neutral-color-500,
    $neutral-color-500,
    $neutral-color-200,
    $neutral-color-500,
    $neutral-color-500
  );
}

.btn {
  &:disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
}

.btn-responsive {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.btn-primary-hover {
  @include button-variant(
    $primary-color-500,
    $primary-color-500,
    $white,
    $primary-color-700,
    $primary-color-700,
    $white
  );

  &:disabled,
  &.disabled {
    color: $primary-color-200;
    background-color: $primary-color-100;
    border: 1px solid $primary-color-100;
  }
}

.btn-secondary-hover {
  background-color: $white;
  border: 1px solid $primary-color-100;

  @include button-outline-variant($primary, $primary, $primary-color-50, $primary-color-100, $primary);

  &:disabled,
  &.disabled {
    border: 1px solid $primary-color-50;
  }
}
