@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.modalPublish {
  &__header {
    background-color: $gray-color-50;

    &--title {
      font-weight: 600;
      font-size: 14px;
      color: #334155;
    }

    &--message {
      color: #64748b;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &__item {
    color: #94a3b8;

    &--title {
      font-size: 14px;
      color: #0f172a;
    }

    &--content {
      font-size: 12px;
      color: #475569;
    }
  }
}
