@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.content-settings {
  &--item {
    padding: 20px 24px;

    border-radius: 16px;
    border: 1px solid $neutral-color-50;
    background: $white;

    box-shadow:
      0px 1px 2px -1px rgba(16, 24, 40, 0.1),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }

  .custom-switch {
    width: 40px;
    left: 0 !important;
  }
}
