@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.libraryList {
  &__list {
    &--layout {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  &__content {
    gap: 12px;
    font-size: $font-size-base;
  }
  &__actions {
    gap: 0;

    &--icon {
      padding: 3px 6px;
      background: $shades-color-white;

      &:hover {
        background: rgba($color: $primary-color-300, $alpha: 0.1);
      }

      &::before {
        font-size: 24px;
        display: inline-block;
        width: 24px !important;
        height: 24px !important;
        color: $primary-color-300;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .libraryList {
    &__list {
      &--layout {
        display: flex;
        flex-direction: column;
      }
    }
    &__actions {
      a:last-child {
        display: block;
      }
    }
  }
  .vertical-collapsed {
    .libraryList {
      &__list {
        &--layout {
          column-gap: $sidebar-width;
        }
      }
    }
  }
}
