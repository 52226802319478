@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.sign-in {
  &-wrapper {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  &--tab {
    flex-basis: 50%;
  }

  &--left {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 1rem;

    &__title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
    }

    &__img {
      width: 0;
    }
  }

  &--right {
    height: 100%;
    padding: 1rem;

    &__title {
      font-size: 40px;
      line-height: 48px;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    .divider {
      width: 100%;
      border: 1px solid $neutral-color-200;
    }

    .sign-up-link {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  &--bg-color {
    background-color: var(--white);
  }

  // Mobile Up
  @include media-breakpoint-up(md) {
    &--left {
      padding: 3rem 2rem;

      &__title {
        font-size: 40px;
        line-height: 48px;
      }

      &__img {
        flex: 1 1 0%;
        width: 25%;
      }
    }

    &--right {
      padding: 7rem;
    }
  }

  // Tablet Up
  @include media-breakpoint-up(lg) {
    &-wrapper {
      flex-direction: row;
    }

    &--left {
      padding: 4rem;
      flex-direction: column;

      &__img {
        margin-top: 3rem;
        width: 100%;
      }
    }

    &--right {
      padding: 6rem;
    }

    &--bg-color {
      background-color: $neutral-color-50;
    }
  }
}

.bg-image {
  background-image: url('../../../assets/images/auth/background-sign-in.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
