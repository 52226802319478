@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.learner-report {
  &-layout {
    background-color: $neutral-color-50;
    border: none;

    @include media-breakpoint-up(md) {
      background-color: $white;

      border: 1px solid $gray-color-100;
    }
  }

  &-wrapper {
    background-color: $neutral-color-50;

    @include media-breakpoint-up(md) {
      background-color: $white;
    }
  }
}
