@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import 'assets/scss/sbox/custom_mixins';

.sidebar-history {
  border-right: 1px solid $neutral-color-200;

  &__question {
    svg {
      width: 16px;
    }

    .more-vertical-icon {
      color: transparent;
    }

    .history-actions-dropdown {
      border-radius: 6px;
      background: $white;
      box-shadow:
        0px 10px 15px -3px rgba(16, 24, 40, 0.1),
        0px 4px 6px -4px rgba(16, 24, 40, 0.1);

      button.dropdown-item {
        padding: 0;
        border-radius: 6px;
      }
    }

    .dropdown-action {
      padding: 6px 8px;
    }

    &:hover {
      background-color: $primary-color-50;

      .more-vertical-icon {
        color: $neutral-color-500;
      }
    }

    &.active {
      background-color: $primary-color-50;
    }
  }

  &__title {
    @include evokio-text-truncate(2);
  }

  &__option {
    padding: 12px;
    background-color: $neutral-color-50;

    &:hover {
      background-color: $primary-color-50;
      box-shadow:
        0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px -1px rgba(16, 24, 40, 0.1);

      .icon {
        background-color: $primary-color-100;
      }

      .arrow-icon {
        color: $primary-color-300;
      }
    }

    .icon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: $neutral-color-200;
      border: 1px solid $primary-color-50;
    }

    .arrow-icon {
      color: transparent;
    }
  }

  .kick-off {
    background: var(--Gradient, linear-gradient(180deg, #1d4ed7 0%, #a4f2fe 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
