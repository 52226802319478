@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.subscription-plans {
  &-page {
    height: 100%;
    background-image: url('~/src/assets/images/auth/change-pw-mobile.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 24px 16px;
    position: relative;

    @include media-breakpoint-up(sm) {
      background-image: url('~/src/assets/images/auth/change-pw-desktop.jpg');
    }

    @include media-breakpoint-up(lg) {
      padding: 72px 8%;
    }

    .workspace-selections {
      flex: 100%;

      @include media-breakpoint-up(lg) {
        flex: 30%;
      }
    }

    .header {
      flex-direction: row;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
    }

    &__logo {
      position: static;
      margin-bottom: 12px;

      @include media-breakpoint-up(lg) {
        position: absolute;
        left: 2%;
        top: 40px;
        margin-bottom: 0;
      }
    }
  }

  &-card {
    padding: 24px 32px;
    border-radius: 16px;
    position: relative;

    .trial-logo {
      position: absolute;
      top: -32px;
      right: 0px;

      @include media-breakpoint-up(lg) {
        top: -36px;
        right: -36px;
      }
    }
  }

  &-title {
    border-bottom: 1px solid $neutral-color-200;
    margin: 0 -32px;

    @include media-breakpoint-up(lg) {
      border-bottom: none;
      margin: 0;
    }

    .plan {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;

      @include media-breakpoint-up(lg) {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        letter-spacing: -0.64px;
      }
    }
  }
}
