@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.mm-disc {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: $neutral-color-200;

  transition: all 0.4s ease-in-out;
}
