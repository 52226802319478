@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.payment-retry-modal {
  gap: 16px;
  border-radius: inherit;

  .invalid-card-error {
    color: $error-color-500;
  }
}
