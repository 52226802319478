@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.contents-page {
  .card-content {
    &__item {
      display: flex;
      align-items: center;
      min-height: 75px;
      border-bottom: 1px solid $neutral-color-200;

      .title-content {
        line-height: 20px;
      }
      .title-original {
        font-size: $font-size-sm;
      }
    }
  }
}
