@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.library-card {
  &__type {
    padding: 20px 20px 0;

    &__img {
      width: 96px;
      @include media-breakpoint-up(sm) {
        width: 100%;
      }
    }
    &__content {
      &__title {
        color: $neutral-color-900;
      }
      &__description {
        color: $neutral-color-400;

        @include media-breakpoint-up(sm) {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  &__btn {
    margin-top: 20px;
    padding: 0px 20px 20px;
  }
}
