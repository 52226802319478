@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.custom-date-picker {
  .react-datepicker {
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $gray-color-200;

    &__header {
      background-color: $white;
      border: 0;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    &__day {
      color: $neutral-color-700;
      margin: 4px 0;
      height: 2.2rem;
      width: 2.2rem;
      text-align: center;
      padding-top: 0.2rem;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-radius: 50%;

      &--keyboard-selected {
        background-color: $primary-color-500;
        color: $white;

        &:hover {
          background-color: $primary-color-500 !important;
        }
      }

      &:hover {
        border-radius: 50%;
        background-color: $neutral-color-100;
      }

      &-name {
        color: $neutral-color-300;
        width: 1.82rem;
      }

      &--in-range,
      &--in-selecting-range {
        background-color: $primary-color-50 !important;
        border-radius: 0 !important;
        border-top: 1px solid $primary-color-100;
        border-bottom: 1px solid $primary-color-100;
        color: $neutral-color-700;
      }

      &--range-start,
      &--selecting-range-start {
        transform-style: preserve-3d;

        &::before {
          content: '';
          background-color: $white !important;
          position: absolute;
          height: 2.2rem;
          width: 50%;
          left: 0;
          top: -1px;
          border: 1px solid white !important;

          transform: translateZ(-1px);
        }

        &::after {
          content: '';
          border-top: 1px solid $primary-color-100;
          border-bottom: 1px solid $primary-color-100;
          position: absolute;
          height: 2.2rem;
          width: 50%;
          right: 0;
          top: -1px;
          background-color: $primary-color-50;
          transform: translateZ(-1px);
        }
      }

      &--range-end,
      &--selecting-range-end {
        border-right: 1px solid $primary-color-100;

        transform-style: preserve-3d;

        &::before {
          content: '';
          border-top: 1px solid $primary-color-100;
          border-bottom: 1px solid $primary-color-100;
          position: absolute;
          height: 2.2rem;
          width: 50%;
          left: 0;
          top: -1px;
          background-color: $primary-color-50;
          transform: translateZ(-1px);
        }

        &::after {
          content: '';
          background-color: $white;
          position: absolute;
          height: 2.2rem;
          width: 50%;
          right: 0;
          top: -1px;
          border: 1px solid white;

          transform: translateZ(-1px);
        }
      }

      &--range-start,
      &--range-end,
      &--selecting-range-start,
      &--selecting-range-end {
        background-color: $primary-color-500 !important;
        border-radius: 50% !important;
        color: $white !important;
      }

      &--disabled {
        color: $neutral-color-400;
      }

      &--today {
        border-radius: 50%;
        background-color: $white;
        color: $neutral-color-700;

        position: relative;
        &::before {
          content: '.';
          color: $primary-color-500;
          font-size: 24px;
          font-weight: 600;
          position: absolute;
          padding-left: 2px;
          padding-top: 4px;
          text-align: center;
        }

        &:hover {
          color: $white;
        }
      }
    }

    &__month {
      margin-top: 0;
    }

    &__triangle {
      display: none;
    }
  }
}
