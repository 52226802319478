@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.header {
  &-wrapper {
    padding: 32px 24px 20px;
    border-bottom: 1px solid $neutral-color-200;

    &--title {
      color: $neutral-color-900;
      letter-spacing: -0.48px;
    }

    .breadcrumb {
      cursor: default !important;
      &-item {
        color: $neutral-color-400;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;

        &.active {
          color: $primary-color-500;
        }
      }
    }
  }

  &-routing {
    padding: 20px 20px 0px;
    border-bottom: 1px solid $neutral-color-200;

    &--title {
      color: $neutral-color-500;
      border-bottom: 3px solid transparent;
      padding: 0 20px 20px;
      transition: 0.3s ease-out border;

      &.active {
        font-weight: 600;
        color: $primary-color-500;
        border-bottom: 3px solid $primary-color-500;

        cursor: default;
      }
    }
  }
}

.profile-wrapper {
  background-color: white;

  // Mobile Up
  @include media-breakpoint-up(md) {
    background-color: $neutral-color-50;
  }
}
