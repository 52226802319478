@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.expired-plan-modal {
  &.modal-wrapper {
    border-radius: 12px;
    background-color: $white;
    box-shadow:
      0px 2px 4px -2px rgba(16, 24, 40, 0.1),
      0px 4px 6px -1px rgba(16, 24, 40, 0.1);
  }

  &--divider {
    width: 100%;
    border: 1px solid $neutral-color-200;
  }

  &--left {
    border-bottom: 1px solid $neutral-color-200;

    @include media-breakpoint-up(lg) {
      border-right: 1px solid $neutral-color-200;
      border-bottom: none;
    }
  }

  &--group-button {
    background: $neutral-color-50;
    border-radius: 8px;
    span {
      border: 1px solid $neutral-color-200;
      border-radius: 24px;
      padding: 2px 10px;
      font-size: 12px;
      font-weight: 400;
      color: $neutral-color-700;
    }
    button {
      &.btn-primary {
        span {
          background: $neutral-color-50;
          color: $primary-color-600;
        }
      }
    }
  }
}
