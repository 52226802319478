@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import 'assets/scss/sbox/custom_mixins';
@import '~bootstrap/scss/mixins';

.content-attempt-layout {
  background-color: $gray-color-50;
  border: none;

  @include media-breakpoint-up(md) {
    background-color: $white;

    border: 1px solid $gray-color-100;
  }

  .content-info-item {
    padding: 8px 16px;

    &:nth-of-type(1) {
      border-top-right-radius: inherit;
      border-top-left-radius: inherit;
    }

    &:nth-of-type(n-1) {
      border-bottom-right-radius: inherit;
      border-bottom-left-radius: inherit;
    }

    &:nth-of-type(odd) {
      background-color: $neutral-color-50;
    }

    div {
      @include evokio-text-truncate(2);

      @include media-breakpoint-up(md) {
        -webkit-line-clamp: 1;
      }
    }
  }

  .score {
    color: $neutral-color-900;
    .question-score {
      color: $neutral-color-500;
      margin-right: 8px;
    }
  }
}
