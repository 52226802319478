@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.payment-methods-wrapper {
  gap: 0px;

  @include media-breakpoint-up(md) {
    gap: 16px;
  }

  .card-element-list {
    flex-wrap: nowrap;
    overflow-y: hidden;
    gap: 16px;

    @include media-breakpoint-up(md) {
      padding: 0;
      opacity: 0.9;
      overflow-x: scroll;
    }

    .card-element-wrapper {
      border-radius: 24px;
      box-shadow:
        0px 2px 4px -2px rgba(16, 24, 40, 0.1),
        0px 4px 6px -1px rgba(16, 24, 40, 0.1);

      transition: transform 0.7s ease-in-out;
      transform: translateY(0);

      &.not-selected {
        transform: translateY(75%);
      }

      @include media-breakpoint-down(md) {
        &:not(:last-of-type) {
          margin-top: -10rem;
        }
      }

      &:nth-of-type(3n + 1) {
        background: radial-gradient(50% 50% at 50% 50%, #6183e4 0%, #1d4ed8 100%);
      }

      &:nth-of-type(3n + 2) {
        background: radial-gradient(50% 50% at 50% 50%, #34d399 0%, #059669 100%);
      }

      &:nth-of-type(3n + 3) {
        background: radial-gradient(50% 50% at 50% 50%, #f59e0b 0%, #f87171 100%);
      }
    }
  }

  .add-card {
    background-color: $white;
    min-width: 198px;
    border-radius: 24px;
    cursor: pointer;

    &-btn {
      background-color: $white;
      padding: 10px;

      border-radius: 50%;
      border: 1px solid $primary-color-500;
    }

    &:hover {
      background-color: $primary-color-50;

      .add-card-btn {
        background-color: $primary-color-50;
      }
    }

    @include media-breakpoint-down(md) {
      width: 325px;

      &-btn {
        height: 28px;
        width: 28px;
        padding: 0;
      }
    }
  }
}
