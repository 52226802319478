@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.owner-name {
  font-weight: $font-weight-medium;
}

.last-modified {
  &__at {
    font-weight: $font-weight-medium;
  }
  &__by {
    font-size: $font-size-sm;
  }
}
