@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import 'assets/scss/variables';

.content-result {
  font-size: 14px;
  margin-bottom: 16px;

  &__title {
    color: $gray-color-700;
    font-weight: 600;
    flex-direction: column;
    justify-content: flex-start;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__score {
    font-weight: 500;
    color: $neutral-color-500;
    margin-right: 0.5rem;
  }
}
