.multiple-choice-question {
  padding-top: 10px;
  padding-bottom: 10px;

  .answers {
    padding-left: 0;
    .answer {
      padding-top: 10px;
      &.answer-true {
        font-style: italic;
        font-weight: 500;
      }
    }
  }

  .answers {
    list-style: none; /* Remove default list styling */
    counter-reset: list-counter; /* Initialize a counter */
  }
  
  .answers li {
    counter-increment: list-counter; /* Increment counter for each list item */
  }
  
  .answers li::before {
    content: counter(list-counter, upper-alpha) ") "; /* Use counter as uppercase letters */
  }
}