@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import 'assets/scss/variables';

.content-result-table,
.content-result-list {
  .content-result-helper {
    padding: 0 16px 16px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    .helper {
      display: flex;
      align-items: center;

      .helper-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        border-radius: 6px;
        margin-right: 0.5rem;

        svg {
          width: 16px;
        }
      }
    }
  }
}
