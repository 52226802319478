@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.folder-settings {
  .folder-tree-item {
    &.edit {
      box-shadow: 0px 0px 0px 4px #e1e1fe;
      border: 1px solid $primary-color-200;
      border-radius: 6px;
    }
    input {
      border: none;
      height: 40px;
      border-radius: 6px;
      border: 1px solid $neutral-color-200;
      caret-color: $primary-color-500;
      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
  }
}
