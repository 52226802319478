@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'assets/scss/sbox/custom_mixins';

.chat-messages-history {
  .custom-badge {
    .app-badge {
      padding: 6px 16px;
      font-size: 14px;
    }
  }

  .previous-brainstorm-topic {
    @include evokio-text-truncate(2);
  }

  .guide {
    border-radius: 12px;
    padding: 12px;
    width: fit-content;
  }
}
