@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

@mixin ai-mascot-size($size) {
  width: $size;
  height: $size;
}

.ai-mascot {
  display: inline-block;
  position: relative;
  --gradient-start-color: #bbcaf3;
  --gradient-end-color: #3460dc;

  @include ai-mascot-size(64px);

  &-large {
    @include ai-mascot-size(44px);
  }

  &-normal {
    @include ai-mascot-size(40px);

    &:hover {
      --gradient-start-color: white;
      --gradient-end-color: white;
    }
  }

  &-medium {
    @include ai-mascot-size(32px);
  }

  &-small {
    @include ai-mascot-size(24px);
  }

  > * {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
  }

  svg {
    @include ai-mascot-size(64px);
  }

  &-large svg {
    @include ai-mascot-size(44px);
  }

  &-normal svg {
    @include ai-mascot-size(40px);
  }

  &-medium svg {
    @include ai-mascot-size(32px);
  }

  &-small svg {
    @include ai-mascot-size(24px);
  }
}

.ai-float .float {
  animation: float 5s infinite alternate;
}

.ai-float.ai-running .float {
  animation: float 1s ease-in-out infinite alternate;
}

@keyframes float {
  0% {
    top: -10px;
  }
  100% {
    top: 10px;
  }
}
