@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.edit-profile-wrapper {
  .role-title {
    background-color: $primary-color-50;
    color: $primary-color-500;
  }

  .input-field {
    input {
      height: 40px !important;
    }
  }
}
