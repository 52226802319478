@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.shareWith {
  &__header {
    background-color: initial;
    border-bottom: none;
    &--title {
      color: $neutral-color-900;
    }
  }

  &__body {
    p {
      color: $neutral-color-700;
      font-size: 12px;
      font-weight: $font-weight-medium;
    }

    &--search-user {
      .dropdown {
        button {
          background: $neutral-color-50;
          border: none !important;
          span {
            font-size: 12px;
          }
        }
        &-menu {
          width: fit-content !important;
          button {
            &:hover {
              background-color: $primary-color-50;
            }
          }
        }
      }
      .select {
        &__control {
          border-color: $neutral-color-200;
          &:hover,
          &:active,
          &:focus {
            border-color: $neutral-color-200;
          }
        }
        &__dropdown-indicator {
          display: none;
        }
        &__clear-indicator {
          cursor: pointer;
        }
        &__indicator-separator {
          display: none;
        }
        &__value-container {
          max-height: 150px;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
        }

        &__multi-value {
          border-radius: 24px;
          background-color: $primary-color-50;
          font-size: $font-size-base;
          color: $primary-color-500;
          cursor: pointer;
          &__label {
            color: $primary-color-500;
          }
          &__remove {
            &:hover {
              color: $primary-color-500;
              background-color: $primary-color-50;
              border-top-right-radius: 24px;
              border-bottom-right-radius: 24px;
            }
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
        &__option {
          cursor: pointer;
        }
      }
    }

    &--people-access {
      .user {
        border: 1px solid $neutral-color-200;
        padding: 10px 12px;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        img {
          width: 32px;
          height: 32px;
        }
        .user-name {
          font-size: $font-size-base;
          font-weight: $font-weight-medium;
          color: $neutral-color-700;
          line-height: 20px;
        }
      }
      .list-user {
        border: 1px solid $neutral-color-200;
        & > div:not(:last-child) {
          margin-bottom: 12px;
        }
        .user-email {
          color: $neutral-color-400;
          font-weight: $font-weight-normal;
          line-height: 16px;
        }
        .dropdown {
          button {
            background: $neutral-color-50;
            border: none !important;
            font-weight: $font-weight-medium;
            font-size: 12px;
          }
          &-menu {
            width: fit-content !important;
            transform: translate3d(-25px, 47px, 0px) !important;
            button {
              &:hover {
                background-color: $primary-color-50;
              }
            }
          }
        }
      }
    }

    &--general-access {
      & > div {
        padding: 10px 12px;
      }
      .dropdown {
        button {
          background: $neutral-color-50;
          border: none !important;
          font-weight: $font-weight-medium;
          font-size: 12px;
        }
        &-menu {
          width: fit-content !important;
          button {
            &:hover {
              background-color: $primary-color-50;
            }
          }
        }
      }
      .workplace {
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
      }
      .desc {
        color: $neutral-color-400;
      }
      .accordion-header {
        div > p {
          font-size: $font-size-base;
        }
      }
      .accordion-body {
        padding: 0;
      }
      .accordion-button {
        padding: 10px 12px;
        border-radius: 0 !important;
      }
      .accordion-item {
        border-color: $neutral-color-200;
      }
    }
  }
}
