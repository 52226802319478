@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.dialog-content {
  .dialog-questions {
    padding-top: 10px;
    padding-bottom: 10px;
    .dialog-question {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  
}

