.not-found-page {
  .container {
    gap: 1.5rem;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 48px;
    font-weight: 600;
    color: #06102b;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #06102b;
    max-width: 24rem;
  }
}

.sun-light {
  transform-origin: center;
  animation: sunlight 2s linear infinite;
}

.wave-light,
.wave-dark {
  animation: move-left 10s linear infinite;
}

.wave-mid {
  animation: move-right 10s linear infinite;
}

@keyframes sunlight {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes move-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes move-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
