@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.drag-drop-upload {
  .drag-drop {
    &-zone {
      border: 1px solid $cool-gray-color-02;
      width: 100%;
      background-color: $generic-white;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    &-icon {
      border: 2px solid $primary-color-100;
      background-color: $primary-color-50;
    }

    &-describe {
      color: $cool-gray-color-09;
    }
  }
}
