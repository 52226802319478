@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.publish {
  li.list-group-item {
    border: none;
  }

  &__radio {
    margin-top: 4px !important;
  }

  &__icon {
    color: $gray-color-400;
    margin-top: -3px !important;
  }

  &__title {
    color: $gray-color-900;
  }

  &__content {
    color: $gray-color-600;
  }
}
