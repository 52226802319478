@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.modal-invite-user {
  .custom-option {
    &-inner {
      border: 1px solid $primary-color-100;
      padding: 6px 12px;
    }
  }

  .form-group .form-control {
    padding: 8px 12px;
    height: 40px;
  }
}
