@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.user-role-badge {
  padding: 4px 12px;
  border-radius: 24px;
  border: 1px solid $neutral-color-500;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: $neutral-color-700;

  &:hover {
    background-color: $neutral-color-200;
  }

  &.isOwner {
    border: 1px solid $warning-color-500;
    color: $warning-color-500;
    background-color: $white;
  }
}
