@import 'assets/scss/sbox/custom_mixins';

.form-group {
  ::placeholder {
    color: $neutral-color-400;
  }
  margin-top: 1rem;

  @include media-breakpoint-up(lg) {
    margin-top: 0.5rem;
  }
  label {
    display: block;
    margin-bottom: 8px;
  }

  .form-control {
    color: $gray-900 !important;
    width: 100%;
    height: 100%;
    font-size: 14px !important;
    line-height: 20px;
    padding: 1rem;
    border: 1px solid $gray-200 !important;
    border-radius: 6px;
    outline: none;
    letter-spacing: 0.01px;

    &.password {
      padding-right: 3rem;
    }

    &.invalid {
      display: block;
      border: 1px solid $error-color-300 !important;
      box-shadow: 0 0 0 4px $error-color-100 !important;
    }
  }
  .feedback-invalid {
    color: $error-color-500;
    margin-top: 8px;
    margin-bottom: 4px;

    font-size: 14px;
    line-height: 20px;
  }
}

// Unset default checkbox on Safari and Firefox
input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}

.selected-libraries {
  &-wrapper {
    overflow-y: scroll;
    max-height: 200px;
  }

  &-item {
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 24px;
  }
}

.custom-libraries-select {
  .dropdown-container {
    color: $neutral-color-900;

    border: 1px solid #e5eaf2 !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;

    border-radius: 6px;
  }

  .dropdown-heading {
    padding: 0 12px !important;
    cursor: pointer;

    &:hover {
      background-color: $primary-color-50;
    }

    * {
      color: $neutral-color-900;
      font-weight: 400;
    }

    @include bg-mobile-hover;
  }

  &.disabled {
    .dropdown-container {
      opacity: 0.65;
    }

    .dropdown-heading {
      border-radius: inherit;

      background-color: $gray-color-50 !important;

      cursor: default;

      span {
        color: $neutral-color-800;
      }
      * {
        color: $neutral-color-400;
      }
    }
  }

  .dropdown-content {
    .select-panel {
      padding: 12px;
      padding-bottom: 0;

      .search {
        color: $neutral-color-400;

        border-radius: 6px;
        border: 1px solid $neutral-color-200;
        background: $white;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        input {
          border-radius: inherit;
          background: $white;
        }
      }

      .options {
        padding: 0;
        margin-top: 8px;
        max-height: 9.375rem !important;

        .select-item {
          display: flex;
          align-items: center;
          outline: none;

          &.selected {
            background-color: unset;
          }

          &:hover {
            background-color: $primary-color-50;
          }
        }
      }
    }
  }
}
