@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import 'assets/scss/sbox/custom_mixins';

.edit-name-form {
  &__textarea {
    width: 100%;
    resize: none;
    border-radius: 6px;
    background-color: $white;
    border: 1px solid $primary-color-100;
    outline: none;
    box-shadow: 0px 0px 0px 4px #e1e1fe !important;
    padding: 8px 12px;

    font-size: 14px;
    font-weight: 400;
    color: $neutral-color-700;
  }

  &__feedback-invalid {
    margin-left: 52px;
    color: $error-color-500;
  }
}
