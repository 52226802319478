#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-1002;
  background-color: $header-bg;
  box-shadow: $box-shadow;
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  padding: 0 calc(#{$grid-gutter-width} / 2) 0 0;
}

.navbar-brand-box {
  margin: auto;
  text-align: center;
  max-width: 100%;
  width: auto;
  transition: all 0.4s ease-in-out;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// dropdown-topbar
.dropdown-topbar {
  .btn-light {
    background-color: $dropdown-topbar !important;
    border: $dropdown-topbar !important;
    &:focus {
      box-shadow: 0 0 0 0.15rem rgba(216, 217, 219, 0.5) !important;
    }
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle='fullscreen'] {
    .mdi-fullscreen::before {
      content: '\F0294';
    }
  }
}

/* Search */

.app-search {
  padding: calc(#{$header-height - 38px} / 2) 0;

  .form-control {
    border: none;
    height: 38px;
    padding-left: 20px;
    padding-right: 40px;
    background-color: $topbar-search-bg;
    box-shadow: none;
    border-radius: 30px;
  }
  span {
    position: absolute;
    z-index: $zindex-10;
    font-size: 13px;
    line-height: 38px;
    right: 16px;
    top: 0;
    color: $gray-700;
  }
}

// Mega menu
.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;
    a {
      color: $dropdown-color;
    }
  }
}

.page-content {
  background: $neutral-color-50;
}

.header-item {
  height: $header-height;
  box-shadow: none !important;
  color: $header-item-color;
  border: 0;
  border-radius: 0px;

  &:hover {
    color: $header-item-color;
  }
}

.header-profile-user {
  height: 36px;
  width: 36px;
  background-color: $gray-300;
}

.noti-icon {
  i {
    font-size: 22px;
    color: $header-item-color;
  }

  .badge {
    position: absolute;
    top: 12px;
    right: 4px;
  }
}

.notification-item {
  .media {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: $gray-100;
    }
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: $gray-600;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    border-color: $gray-200;
  }
}
