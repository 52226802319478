@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.refine-modal-wrapper {
  .refine-modal-section {
    gap: 12px;
  }

  .area-wrapper {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid $neutral-color-200;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .area-input {
    border: none;
    resize: none;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  button {
    svg {
      width: 20px;
    }
  }

  .divider {
    height: 1px;
  }

  .submit-btn {
    height: 36px;
    width: 36px;
    padding: 10px;
  }

  .back-btn {
    color: $neutral-color-500;

    &:hover {
      color: $primary;
    }
  }
}
