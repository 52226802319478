@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.library-card {
  box-shadow: 0px 12px 24px 0px rgba(18, 38, 63, 0.03);
  transition: transform 0.2s ease-out;

  &:hover {
    transform: translateY(-8px);
  }
}
