@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.publishItem {
  height: 56px;
  width: 100%;
  background-color: $shades-color-white;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid $primary-color-50;

  &__overlay {
    height: 40px;
    background-color: $gray-color-100;
    border-left: 4px solid $primary-color-500;
    padding: 8px 16px;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
  }

  &__arrowIconBtn {
    width: 24px;
    height: 24px;
    border-radius: 50%;

    > i {
      transition: 0.4s;

      &.selected {
        transform: rotate(90deg);
      }
    }

    &:hover {
      background-color: $primary-color-50;

      & > i {
        color: $primary-color-500;
      }
    }
  }
}
