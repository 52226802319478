@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.chat-input-loading {
  .spinner-grow {
    width: 0.75rem;
    height: 0.75rem;
  }

  .loading-wrapper {
    width: fit-content;
    border-radius: 12px;
    padding: 12px;
    background: $neutral-color-50;
  }
}
