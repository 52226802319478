.content-lti-settings {
  .max-width-setting {
    div {
      width: auto !important;
      display: inline-block !important;
    }
    input {
      height: 40px;
      width: 64px !important;
      padding: 8px 12px !important;
    }
  }
}
