@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.billing-history {
  &-wrapper {
    padding: 20px;
    gap: 16px;

    border-radius: 16px;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
}
