@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.bottom-actions {
  padding: 12px 16px;
  border: 1px solid $neutral-color-200;
  border-radius: 16px;
  box-shadow: 0px 2px 15px 2px rgba(29, 78, 216, 0.1);
}

.loading-text {
  color: $neutral-color-900;
  font-size: 14px;
  font-weight: 400;
}
