@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.content-actions {
  &-dropdown {
    width: 335px;
    border-radius: 8px;
    box-shadow:
      2px 2px 4px -2px rgba(16, 24, 40, 0.1),
      3px 4px 6px -1px rgba(16, 24, 40, 0.1);
  }

  &__sub-menu {
    left: -48px !important;
    bottom: -12px !important;

    &.status-menu {
      .disabled {
        .icon > svg,
        .label {
          color: $gray-color-300;
        }
      }
    }
  }
}
