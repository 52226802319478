@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.page-header {
  z-index: $zindex-100;

  &__title {
    font-size: 18px;
    color: $gray-color-900;

    @include media-breakpoint-up(sm) {
      font-size: $h4-font-size;
    }
  }

  &__main {
    min-height: 80px;
  }

  &__back {
    color: $neutral-color-500;

    &:hover {
      color: $primary;
    }
  }

  &__divider {
    width: 1px;
    height: 40px;
    background-color: $gray-color-200;
  }
}

.navbar-brand-box__header {
  width: $sidebar-collapsed-width;
}
