@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.library {
  &-list {
    grid-template-columns: 1fr;
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;
    }

    .custom-switch {
      width: 40px;
      height: 24px;
    }
  }
}

.content-type__header {
  border-bottom: none;

  @include media-breakpoint-up(md) {
    border-bottom: 1px solid $neutral-color-200;
  }
}
