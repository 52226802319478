@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.content-result-list {
  display: flex;
  flex-direction: column;

  &__title,
  &__question {
    font-size: 14px;
    padding: 0 16px;
  }

  &__title {
    margin: 16px 0;
    color: $gray-color-700;
    font-weight: 600;
  }

  &__inner {
    padding: 16px;
  }

  .answer-card {
    border: 1px solid $neutral-color-200;
    border-radius: 8px;

    &__header {
      border-radius: 8px 8px 0 0;
      background-color: $neutral-color-50;
      border-bottom: 1px solid $neutral-color-200;
      padding: 16px 12px;

      color: $gray-color-700;
      font-weight: 600;
    }

    &__body {
      padding: 16px 12px;
      border-radius: 0 0 8px 8px;
    }
  }
}
