@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import 'assets/scss/sbox/custom_mixins';

.chat-card {
  padding: 12px 16px;
  border: 1px solid $primary-color-50;
  border-radius: 12px;
  gap: 16px;

  &:hover {
    background-color: $neutral-color-100;
    box-shadow:
      3px 4px 6px -1px rgba(16, 24, 40, 0.1),
      1px 2px 4px -2px rgba(16, 24, 40, 0.1);

    transition: all 0.4s ease;
  }

  &__icon-action {
    color: $neutral-color-500;

    &:hover {
      color: $primary-color-300;
    }
  }
}

.line-clamp-2 {
  @include evokio-text-truncate(2);
}
