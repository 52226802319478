@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.add-card-modal {
  &__wrapper {
    background-color: $generic-white;
    border-radius: 8px;
    box-shadow:
      0px 8px 10px -6px rgba(16, 24, 40, 0.1),
      0px 20px 25px -5px rgba(16, 24, 40, 0.1);
  }

  &__body {
    margin-bottom: 32px;
  }
}
