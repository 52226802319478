@import './custom_mixins';
@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

#root,
body,
html {
  height: 100%;
  overflow: hidden;
}

body {
  // Prevent scrolling when open modal on Safari/Iphone
  &.modal-open {
    @include media-breakpoint-down(md) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}

.bg-mobile-hover {
  @include bg-mobile-hover;
}
