@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import 'assets/scss/variables';

.content-view-page {
  .total-attempts {
    display: inline-flex;
    background-color: $primary-color-500;
    border-radius: 50%;
    width: 28px;
    height: 28px;
  }
}

.last-percentage-score {
  @include media-breakpoint-up(md) {
    margin-bottom: 2px;
  }
}
