@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.app-dropdown {
  &__custom-dropdown {
    min-width: 7rem;

    button.disabled {
      background-color: $neutral-color-200 !important;
    }

    &.invalid {
      border: 1px solid $error-color-300;
      box-shadow: 0 0 0 4px $error-color-100;
    }
  }

  &__dropdown-menu-custom {
    .dropdown-item:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    .dropdown-item:last-child {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}
