@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.dashboardHeader {
  width: 100%;
  min-height: 120px;
  background-color: $primary-color-500;

  &__nav {
    width: 800px;
    height: 50px;
    background-color: $shades-color-white;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;

    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-item,
    .nav-link {
      border-color: transparent !important;
      color: $primary-color-300;
      display: inline-block;
    }

    .nav-link {
      border-bottom: 2px solid transparent;

      &.active {
        border-bottom-color: $primary-color-400 !important;
        color: $primary-color-400 !important;
        border-width: 2px;
      }

      &:hover {
        border-color: transparent;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .dashboardHeader {
    &__contentActions {
      flex-direction: column;
      justify-content: start;
      padding-bottom: 80px;
    }

    &__title {
      margin: 0 auto;
    }

    &__actions {
      margin: 0 auto;
    }

    &__nav {
      width: calc(100vw - 70px) !important;
      left: 50% !important;
      transform: translate(-50%, 0);
    }
  }
}
