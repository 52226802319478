@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.buttonHeader {
  border: 1px solid $gray-color-50 !important;

  &.active {
    background-color: $primary-color-200 !important;
  }
}
