@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.Toastify {
  &__toast {
    &--success,
    &--default {
      border-left: 8px solid $success-color-200;
    }

    &--warning {
      border-left: 8px solid $warning-color-200;
    }

    &--error {
      border-left: 8px solid $error-color-200;
    }

    &--info {
      border-left: 8px solid $primary-color-200;
    }
  }
  &__toast-container {
    &--top-right {
      top: 6rem !important;
    }
  }
}
