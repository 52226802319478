@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import 'assets/scss/sbox/custom_mixins';

.kick-off-chat {
  font-weight: 600;
  background: linear-gradient(to bottom, #1d4ed7, #a4f2fe);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
