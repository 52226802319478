@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.app-custom-search {
  &.search-wrapper {
    height: 40px;
    border-radius: 6px;
    border: 1px solid $neutral-color-200;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .search-input {
    border: none;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}
