@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.subscription-card {
  padding: 16px;
  border-radius: 16px;
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px -1px rgba(16, 24, 40, 0.1);

  @include media-breakpoint-up(lg) {
    padding: 0;
    padding-right: 24px;
    box-shadow: none;
    border-radius: 0;
  }

  .badge {
    padding: 4px 12px;
    font-size: 14px;
    font-weight: 500;
    &-active {
      color: $success-color-500;
      background-color: $success-color-50;
    }
    &-trialing {
      color: $warning-color-600;
      background: $warning-color-50;
    }
    &-canceled {
      color: $neutral-color-500;
      background: $neutral-color-50;
    }
  }

  &-period {
    padding-bottom: 12px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 0;
    }
  }

  &-info {
    display: flex;

    border-top: 1px solid $neutral-color-200;

    padding: 12px 0;

    @include media-breakpoint-up(lg) {
      justify-content: center;
      border-top: 0;
      border-left: 1px solid $neutral-color-200;
      padding: 0 0 0 24px;
    }
  }
}
