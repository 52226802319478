.folder {
  .accordion-item,
  .list-group-item {
    border: none;
  }

  &__tree {
    span.rc-tree-switcher {
      background-image: none !important;
      width: 25px !important;
      height: 25px !important;
    }
    .rc-tree-treenode {
      padding: 2px 0;
      &.drop-target {
        // TODO: background-color changes when dragged over
      }
    }

    .rc-tree-node-selected {
      background-color: transparent;
      box-shadow: none;
    }
  }
}
