@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.library {
  &__view-modes {
    width: max-content;
    margin-top: 4px;
    padding: 4px;
    border-radius: 24px;
    background-color: $gray-color-100;

    &__btn {
      font-weight: 500;
      padding: 4px 8px;

      &.active {
        background-color: $primary-color-500;
        color: $white;
      }
    }

    @include media-breakpoint-up(xsm) {
      margin-top: 0px;
      padding: 4px;

      &__btn {
        padding: 8px 16px;
        font-weight: 500;

        &.active {
          background-color: $primary-color-500;
          color: $white;
        }
      }
    }
  }
}
