@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.sidebar-collapsed-btn {
  color: $primary-color-500;
  background-color: $primary-color-50;

  &:hover,
  &:active {
    color: $primary-color-500;
    background-color: $primary-color-50;
  }
}

.vertical-collapsed {
  .sidebar-collapsed-btn {
    transform: rotate(-180deg);
  }
}

@include media-breakpoint-up(lg) {
  .sidebar-collapsed-btn {
    transform: rotate(-180deg);
  }

  .vertical-collapsed {
    .sidebar-collapsed-btn {
      transform: rotate(0deg);
    }
  }
}
