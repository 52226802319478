@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.sign-up {
  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &--tab {
    flex-basis: 50%;
  }

  &--left {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 1rem;

    &__title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
    }

    &__img {
      width: 0;
    }
  }

  &--right {
    height: 100%;
    padding: 1rem;
    padding-bottom: 4.5rem;

    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.8px;
    }

    .divider {
      width: 100%;
      border: 1px solid $neutral-color-200; // neutral-200
    }

    .password-guide {
      color: $neutral-color-400; // neutral-400

      font-size: 12px;
      line-height: 16px;
    }
  }

  &--bg-color {
    background-color: var(--white);
  }

  // Mobile Up
  @include media-breakpoint-up(md) {
    &--left {
      padding: 3rem 2rem;

      &__title {
        font-size: 40px;
        line-height: 48px;
      }

      &__img {
        display: block;

        width: 25%;
      }
    }

    &--right {
      padding: 7rem;

      &__title {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.8px;
      }
    }
  }

  // Tablet Up
  @include media-breakpoint-up(lg) {
    &-wrapper {
      flex-direction: row;
    }

    &--left {
      padding: 4rem;
      flex-direction: column;

      &__img {
        margin-top: 3rem;

        width: 100%;
      }
    }

    &--right {
      padding: 6rem;
    }

    &--bg-color {
      background-color: $neutral-color-50;
    }
  }
}

.checkbox-lg .form-check-input {
  min-width: 18px;
  height: 18px;

  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
}
