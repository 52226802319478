@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.lti-setting {
  &-layout {
    height: 100%;
    border-radius: 16px;

    box-shadow:
      0px 1px 2px -1px rgba(16, 24, 40, 0.1),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    @include media-breakpoint-up(md) {
      height: auto;
    }
  }

  &-title {
    border-bottom: 1px solid $neutral-color-200;
  }

  &-item {
    border-radius: 12px;
    border: 1px solid $neutral-color-200;

    .custom-switch {
      width: 40px;
      left: 0 !important;
    }

    &__option {
      border-top: 1px solid $neutral-color-200;
    }

    .setting-dropdown {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 380px;
      }
    }

    .max-width-setting {
      div {
        width: auto !important;
        display: inline-block !important;
      }
      input {
        height: 40px;
        width: 64px !important;
        padding: 8px 12px !important;
      }
    }
  }
}
