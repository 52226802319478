@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.dropzone-wrapper {
  &.disable {
    pointer-events: none;
    cursor: not-allowed;
    .drag-drop-section {
      &.drag-active,
      &:hover {
        border-color: transparent;
        background: inherit;
      }
    }
  }

  .drag-drop-section {
    background: $neutral-color-100;
    height: 120px;
    border: 1px dashed transparent;
    &.drag-active,
    &:hover {
      border-color: $primary-color-400;
      background: $primary-color-50;
      .icon-upload {
        color: $primary-color-400;
      }
    }
  }
}
