@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.billing-information {
  &-wrapper {
    padding: 20px;
    gap: 16px;

    border-radius: 16px;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .input-field {
      input {
        height: 40px !important;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;

        &:focus {
          border-color: hsla(210, 96%, 45%, 50%) !important;
          box-shadow:
            0px 1px 1px rgba(0, 0, 0, 0.03),
            0px 3px 6px rgba(0, 0, 0, 0.02),
            0 0 0 3px hsla(210, 96%, 45%, 25%),
            0 1px 1px 0 rgba(0, 0, 0, 0.08) !important;
        }
      }
    }

    .btn-save,
    .btn-cancel {
      padding: 10px 14px;
      margin-top: 16px;
    }
  }

  &__buttons {
    opacity: 0;
    height: 0;
    transition:
      opacity 0.7s ease-out,
      height 0.7s ease-out;

    &.display {
      opacity: 1;
      height: auto;
    }
  }
}
