@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.admin-content-type-table {
  table.app-custom-table thead th {
    vertical-align: baseline;
    text-align: center;
  }
}
