@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.user-status-badge {
  padding: 4px 12px;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  &.active {
    background-color: $success-color-50;
    color: $success-color-600;
  }

  &.inactive {
    background-color: $error-color-50;
    color: $error-color-600;
  }

  &.pending {
    background-color: $neutral-color-50;
    color: $neutral-color-900;
  }
}
