@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.close-icon {
  transition: 0.6s ease-out;

  &:hover {
    color: $primary-color-500;
    transform: rotate(180deg);
  }
}

.contents-filter-modal {
  &.modal-wrapper {
    background-color: $generic-white;
    border-radius: 6px;
    box-shadow:
      0px 4px 6px -4px rgba(16, 24, 40, 0.1),
      0px 10px 15px -3px rgba(16, 24, 40, 0.1);
  }
}
