@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.content-result-table,
.content-result-list {
  .answer {
    padding: 2px 8px;
    border-radius: 6px;
    color: $neutral-color-500;

    svg {
      width: 20px;
    }
  }

  .correct-answer {
    background-color: $success-color-50;
    border: 1px dashed $success-color-500;
    color: $success-color-500;
  }

  .incorrect-answer {
    background-color: $error-color-50;
    border: 1px solid $error-color-500;
    color: $error-color-500;
  }

  .learner-correct-answer {
    background-color: $success-color-50;
    border: 1px solid $success-color-500;
    color: $success-color-500;
  }
}
