@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.chat-list {
  .ai-response {
    border-radius: 8px;
    border: 1px solid $primary-color-50;

    button {
      svg {
        width: 20px;
      }
    }

    .action-icon {
      height: 36px;
      width: 36px;
      padding: 10px;
      border-radius: 40px;

      &:hover {
        background-color: $primary-color-50;
      }

      svg {
        &:focus {
          outline: none;
        }
      }
    }
  }

  .custom-badge {
    .app-badge {
      padding: 6px 16px;
      font-size: 14px;
    }
  }

  .human-chat {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .custom-markdown {
    p {
      margin-bottom: 1rem;
      em {
        font-weight: 500;
      }
    }
  }
}
