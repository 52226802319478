@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.user-text-prompt {
  max-width: 90%;

  .prompt {
    border-radius: 8px;
    border: 1px solid $primary-color-50;
    background: $primary-color-50;
    white-space: pre-line;
    word-wrap: break-word;
  }
}
