@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.checkbox {
  &__input {
    &.form-check-input {
      margin-top: 0px;
      width: 16px;
      height: 16px;
      position: relative;
      z-index: 0;

      &:checked {
        background-color: $shades-color-white;
        background-image: none !important;

        &::after {
          background-image: url('../../../assets/images/check.svg');
          background-repeat: no-repeat;
          position: absolute;
          content: '';
          z-index: 2;
          top: 0;
          right: 1px;
          width: 12px;
          height: 12px;
          background-color: transparent;
        }
      }

      &:active {
        filter: brightness(100%);
      }
    }
  }
}
