@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.create-content-modal {
  &.modal-wrapper {
    border-radius: 8px;
    background-color: $white;
    box-shadow:
      0px 2px 4px -2px rgba(16, 24, 40, 0.1),
      0px 4px 6px -1px rgba(16, 24, 40, 0.1);
  }
}
