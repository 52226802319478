@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.expanded-content {
  &-attempts {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 16px 48px;
    }
  }

  &-attempt {
    margin-bottom: 8px;
    padding: 12px 16px;
    border-radius: 12px;

    @include media-breakpoint-up(md) {
      padding: 8px 20px;
    }

    .attempt-time {
      width: auto;

      @include media-breakpoint-up(md) {
        width: 30%;
      }
    }
  }
}
