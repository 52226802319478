@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.plan-options {
  &-layout {
    height: 100%;

    background-color: $white;

    @include media-breakpoint-up(lg) {
      height: auto;
    }
  }

  &-card {
    border-radius: 8px;
    background-color: $white;
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.1),
      0px 1px 2px -1px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    position: relative;

    &.standard-plan {
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
    }

    &--icon {
      position: absolute;
      left: -12px;
      top: -14px;
    }

    &--info {
      flex: 65%;
      padding: 16px 24px;
      border-radius: inherit;

      &__report-line {
        padding: 0px 16px;
        display: flex;
        align-items: start;
        gap: 12px;
        margin-top: 8px;

        .icon {
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 99%;

          background-color: $neutral-color-50;
        }
      }

      &.standard-plan {
        background-color: $primary-color-500;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;

        @include media-breakpoint-up(lg) {
          border-top-right-radius: unset;
          border-bottom-left-radius: inherit;
        }

        .credit-card-icon {
          @include media-breakpoint-down(lg) {
            svg {
              height: 66;
              width: 66;
            }
          }
        }
      }

      .beta-badge {
        display: flex;
        padding: 6px 16px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 24px;
        background: $warning-color-100;
        color: $warning-color-600;
      }
    }

    &--price {
      padding: 16px 24px;
      background-color: $gray-color-50;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;

      &.standard-plan {
        flex: 30%;
        justify-content: center;
        background-color: $primary-color-50;

        @include media-breakpoint-up(lg) {
          border-bottom-left-radius: unset;
          border-top-right-radius: inherit;
          border-bottom-right-radius: inherit;
        }
      }

      &.ai-assistant-price {
        @include media-breakpoint-down(lg) {
          height: auto !important;
        }
      }

      .free-badge {
        display: flex;
        padding: 6px 16px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 24px;
        background-color: $primary-color-50;
        margin-bottom: 8px;
      }
    }

    &__report-btn {
      width: 100%;
      button {
        width: 100%;
        padding: 10px 14px;
      }
    }
  }

  &-footer {
    margin-top: 20px;
    gap: 10px;

    button {
      padding: 10px 14px;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 188px;
      }
    }
  }
}
