@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.avatar-wrapper {
  font-weight: 500;

  &__image {
    object-fit: cover;
  }

  &--outline {
    border: 3px solid $primary-color-100 !important;
  }

  &.avatar-settings {
    font-size: $h3-font-size;

    @include media-breakpoint-up(sm) {
      font-size: $h2-font-size;
    }
  }
}
