.user-detail {
  .accordion-item {
    border-radius: 12px;
  }

  .accordion-button {
    border-radius: 12px !important;

    &__icon {
      transition: transform 0.2s ease-in-out;

      &--open {
        transform: rotate(90deg);
      }
    }
  }

  .accordion-button::after {
    display: none !important;
  }
}
