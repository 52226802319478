@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.connection-name {
  &__table {
    .row {
      &:nth-child(odd) {
        background-color: $neutral-color-50;
      }
    }
  }
}
