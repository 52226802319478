@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.password {
  &-wrapper {
    background-image: url('~/src/assets/images/auth/change-pw-mobile.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &-form {
    width: 500px;
    padding: 24px 16px;
    margin: 0 16px;
    gap: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);

    &--title {
      color: $neutral-color-800;

      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
      letter-spacing: -0.72px;
    }

    &--description {
      color: $neutral-color-800;

      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }
  }

  @include media-breakpoint-up(sm) {
    &-wrapper {
      background-image: url('~/src/assets/images/auth/change-pw-tablet.jpg');
    }

    &-form {
      padding: 40px;
      margin: 0;

      &--title {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: -0.8px;
      }

      &--description {
        padding: 0 1rem;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &-wrapper {
      background-image: url('~/src/assets/images/auth/change-pw-desktop.jpg');
    }
  }

  &-guide {
    color: $neutral-color-400; // neutral-400

    font-size: 12px;
    line-height: 16px;
  }
}
