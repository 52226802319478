@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.users-page {
  .users-list {
    &__item {
      padding: 20px;
      border-bottom: 1px solid $neutral-color-200;
    }
  }
}
