// Gray Color
$gray-color-50: #f8fafc;
$gray-color-100: #f1f5f9;
$gray-color-200: #e2e8f0;
$gray-color-300: #cbd5e1;
$gray-color-400: #94a3b8;
$gray-color-500: #64748b;
$gray-color-600: #475569;
$gray-color-700: #334155;
$gray-color-800: #1e293b;
$gray-color-900: #0f172a;

// Primary Color
$primary-color-50: #e8edfb;
$primary-color-100: #bbcaf3;
$primary-color-200: #8ea7ec;
$primary-color-300: #6183e4;
$primary-color-400: #3460dc;
$primary-color-500: #1d4ed8;
$primary-color-600: #173ead;
$primary-color-700: #112f82;
$primary-color-800: #0c1f56;
$primary-color-900: #06102b;

// Secondary color
$secondary-color-50: #fefaed;
$secondary-color-100: #fdf0c8;
$secondary-color-200: #fbe7a3;
$secondary-color-300: #f9dd7e;
$secondary-color-400: #f8d359;
$secondary-color-500: #f7ce46;
$secondary-color-600: #c6a538;
$secondary-color-700: #947c2a;
$secondary-color-800: #63521c;
$secondary-color-900: #31290e;

// Success Color
$success-color-50: #ecfdf5;
$success-color-100: #d1fae5;
$success-color-200: #a7f3d0;
$success-color-300: #6ee7b7;
$success-color-400: rgba(52, 211, 153, 0.2);
$success-color-500: #10b981;
$success-color-600: #059669;
$success-color-700: #047857;
$success-color-800: #065f46;
$success-color-900: #064e3b;

// Warning Color
$warning-color-50: #fffbeb;
$warning-color-100: #fef3c7;
$warning-color-200: #fde68a;
$warning-color-300: rgba(252, 211, 77, 0.2);
$warning-color-400: #fbbf24;
$warning-color-500: #f59e0b;
$warning-color-600: #d97706;
$warning-color-700: #d97706;
$warning-color-800: #92400e;
$warning-color-900: #78350f;

// Error Color
$error-color-50: #fef2f2;
$error-color-100: #fee2e2;
$error-color-200: #fecaca;
$error-color-300: #fca5a5;
$error-color-400: rgba(248, 113, 113, 0.2);
$error-color-500: #ef4444;
$error-color-600: #dc2626;
$error-color-700: #b91c1c;
$error-color-800: #991b1b;
$error-color-900: #7f1d1d;

// Shades
$shades-color-white: #ffffff;
$shades-color-black: #000000;

// Neutral color
$neutral-color-50: #f8fafc;
$neutral-color-100: #f1f5f9;
$neutral-color-200: #e2e8f0;
$neutral-color-300: #cbd5e1;
$neutral-color-400: #94a3b8;
$neutral-color-500: #64748b;
$neutral-color-600: #475569;
$neutral-color-700: #334155;
$neutral-color-800: #1e293b;
$neutral-color-900: #0f172a;

$white: #fff;
$generic-white: #fcfcfc;

// Cool gray color
$cool-gray-color-02: #e7ebef;
$cool-gray-color-09: #282e37;

// Z-index
// search: scss-docs-start zindex-stack => watch value about z-index bootstrap

$zindex-5: 5;
$zindex-6: 6;
$zindex-10: 10;
$zindex-100: 100;
$zindex-1001: 1001;
$zindex-1002: 1002;
$zindex-9999: 9999;

// Dropdown

$dropdown-link-color: $gray-color-900;
$dropdown-link-hover-color: $gray-color-900;
$dropdown-link-hover-bg: $primary-color-50;

// Navs Tabs

$nav-link-padding-y: 1rem;
$nav-link-padding-x: 0.5rem;
$nav-link-font-size: null;
$nav-link-font-weight: null;
$nav-link-color: $link-color;
$nav-link-hover-color: $link-hover-color;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $gray-300;
$nav-tabs-border-width: 1px;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $primary-color-500;
$nav-tabs-link-active-bg: $white;
$nav-tabs-link-active-border-color: $primary-color-500;
