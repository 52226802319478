@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.content-attempts-modal {
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 8px 10px -6px rgba(16, 24, 40, 0.1),
    0px 20px 25px -5px rgba(16, 24, 40, 0.1);
  overflow-y: scroll;

  &__header {
    background-color: $white;
    z-index: 2;
  }

  .content-title {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
