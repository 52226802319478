@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.app-badge {
  padding: 4px 12px;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  &.success {
    background-color: $success-color-50;
    color: $success-color-600;
  }

  &.error {
    background-color: $error-color-50;
    color: $error-color-600;
  }

  &.warning {
    background-color: $warning-color-50;
    color: $warning-color-600;
  }

  &.neutral {
    background-color: $neutral-color-50;
    color: $neutral-color-900;
  }

  &.success-outline {
    background-color: transparent;
    color: $success-color-500;
    border: 1px solid $success-color-500;
  }

  &.warning-outline {
    background-color: transparent;
    color: $warning-color-500;
    border: 1px solid $warning-color-500;
  }

  &.error-outline {
    background-color: transparent;
    color: $error-color-500;
    border: 1px solid $error-color-500;
  }

  &.neutral-outline {
    background-color: transparent;
    color: $neutral-color-500;
    border: 1px solid $neutral-color-500;
  }

  &.primary-light {
    background-color: $primary-color-50;
    color: $primary-color-600;
    border: 1px solid transparent;
  }

  &.primary {
    background-color: $primary-color-500;
    color: $white;
    border: 1px solid $primary-color-500;
  }

  &.neutral-outline-hover {
    background-color: transparent;
    color: $neutral-color-700;
    border: 1px solid $neutral-color-200;

    &:hover {
      background-color: $neutral-color-200;
    }
  }

  &.primary-outline-hover {
    background-color: $primary-color-50;
    color: $primary-color-600;

    &:hover {
      background-color: $primary-color-100;
    }
  }

  &.ai-hint-text-hover {
    background-color: $neutral-color-50;
    color: $neutral-color-900;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: $neutral-color-100;
    }
  }
}

.nowrap {
  white-space: nowrap;
}
