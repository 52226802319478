@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.plan-information {
  &-layout {
    height: 100%;
    border-radius: 16px;

    box-shadow:
      0px 1px 2px -1px rgba(16, 24, 40, 0.1),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    @include media-breakpoint-up(md) {
      height: auto;
    }
  }

  &-title {
    border-bottom: 1px solid $neutral-color-200;
  }

  &-item {
    & > div:first-child {
      flex-direction: column;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }
    }
  }
}
