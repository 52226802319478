@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.upload-content-wrapper {
  .modal-footer {
    button {
      width: 72px;
    }
    .btn-ok {
      &.disabled {
        background-color: $primary-color-100;
        color: $primary-color-200;
        border-color: transparent;
      }
    }
  }
}
