@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.user-action-modal {
  &.modal-wrapper {
    background-color: $white;
    border-radius: 16px 16px 0 0;
    box-shadow:
      0px 8px 10px -6px rgba(16, 24, 40, 0.1),
      0px 20px 25px -5px rgba(16, 24, 40, 0.1);
  }

  .modal-header {
    border-bottom: 1px solid $neutral-color-200;
  }

  .action-item {
    padding: 12px 16px;
    border-bottom: 1px solid $neutral-color-200;

    cursor: pointer;
  }
}
