@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.library {
  &__nav {
    &__sort-by {
      padding-left: 16px;

      @include media-breakpoint-up(sm) {
        height: 100%;
        padding-left: 24px;
      }

      &__item {
        padding: 16px 12px 12px;
        color: $neutral-color-500;
        font-size: $font-size-base;
        border-bottom: 2px solid transparent;

        transition: border-bottom 0.2s ease-in-out;

        @include media-breakpoint-up(sm) {
          font-size: $font-size-medium;

          padding: 16px 24px;
          height: 100%;
        }

        &.active {
          color: $primary;
          font-weight: 600;
          border-bottom: 2px solid $primary;
        }
      }
    }

    &__search {
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: fit-content;
        margin-left: 48px;
      }
      &__icon {
        left: 24px;
        color: $neutral-color-400;
      }

      &__input {
        padding-left: 40px;
        outline: none;

        &::placeholder {
          color: $neutral-color-500;
        }
      }
    }
  }
}
