@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.app-custom-table {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  &.table-wrapper {
    background-color: $white;
    border-radius: 8px;
  }

  thead {
    tr {
      th {
        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }
  }

  tbody {
    tr:last-child {
      border: none;
    }
  }

  .row-wrapper {
    border-bottom: 1px solid $neutral-color-200;

    transition: background-color 0.3s ease-out;

    .title-cell--actions {
      display: none;
    }

    .title-cell--starred {
      display: flex;
    }

    &:hover {
      background-color: $neutral-color-50;

      .title-cell--actions {
        display: flex;
      }

      .title-cell--starred {
        display: none;
      }
    }

    &.selected {
      background-color: $primary-color-50;
    }
  }

  .expanded-row {
    border-bottom: 1px solid $neutral-color-200;
  }
}
