@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.dropdown-assign-role {
  &__radio {
    &:hover {
      background-color: $primary-color-50;
    }

    &--selected {
      background-color: $primary-color-50;
    }
  }
}
