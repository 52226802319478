@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.block-wrapper {
  width: 500px;

  &--title {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.72px;
  }

  &--description {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
}
