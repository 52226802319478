@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.invoice-list {
  &--item {
    padding: 20px 16px;

    border-bottom: 1px solid $neutral-color-200;
  }
}
