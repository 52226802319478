@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.workspace-settings-wrapper {
  border-radius: 0;

  @include media-breakpoint-up(md) {
    border-radius: 16px;
  }

  .input-field {
    input {
      height: 40px !important;
    }
  }
}
