@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.content-result-table {
  table {
    box-shadow: 0px 1px 2px 0px $neutral-color-200;
    border-radius: 8px;

    thead {
      tr {
        th {
          &:first-child {
            border-top-left-radius: 8px;
          }
          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }
    }

    tbody {
      tr:last-child {
        border: none;
      }
    }

    thead > tr > th {
      width: calc(100% / 3);
    }

    th,
    td {
      padding: 0.75rem 1rem;
      vertical-align: top;
    }

    tr {
      border-bottom: 1px solid $neutral-color-200;
    }

    thead {
      background-color: $neutral-color-50;
    }
  }

  &__title,
  &__question {
    font-size: 14px;
    margin-bottom: 16px;
  }

  &__title {
    color: $gray-color-700;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
  }

  &__score {
    font-weight: 500;
    color: $neutral-color-500;
    margin-right: 0.5rem;
  }
}
