@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.new-content-modal {
  background-color: $white;
  border-radius: 8px;
  box-shadow:
    0px 8px 10px -6px rgba(16, 24, 40, 0.1),
    0px 20px 25px -5px rgba(16, 24, 40, 0.1);
  overflow-y: scroll;

  &__header {
    background-color: $white;
    z-index: 2;

    .header-top {
      border-bottom: 1px solid $neutral-color-200;

      .back-button {
        color: $neutral-color-500;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .btn-view-mode {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    padding: 10px;

    background-color: $white;
    border: 1px solid $primary-color-500;

    transition: background-color 0.4s ease-out;

    cursor: pointer;

    &.gallery {
      border-radius: 6px 0px 0px 6px;
    }

    &.list {
      border-radius: 0px 6px 6px 0px;
    }

    &.active {
      background-color: $primary-color-500;
      cursor: default;
    }
  }
}
