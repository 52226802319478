@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.radio {
  &__input {
    &.form-check-input {
      margin-top: 0px;
      width: 18px;
      height: 18px;

      &:checked {
        background-color: $shades-color-white;
        background-image: url('../../../assets/images/checkbox.svg') !important;
        background-size: 8px;
        border: 1px solid $primary-color-500;
      }

      &:active {
        filter: brightness(100%);
      }
    }
  }
}
