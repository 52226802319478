@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';
@import 'assets/scss/sbox/custom_mixins';

.user-upload-wrapper {
  max-width: 90%;

  .user-upload-prompt {
    border-radius: 8px;
    border: 1px solid $primary-color-50;
  }

  .list-file {
    width: fit-content;
    padding: 6px 12px;
    background-color: $white;
    border-radius: 10px;

    .file-name {
      @include evokio-text-truncate(1);
    }
  }
}
