@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.offcanvas.offcanvas-end {
  background-color: white;
  top: unset;
  bottom: 0;
  height: calc(100% - 32px);
  min-width: 50vw;
  border-radius: 24px;
  box-shadow:
    0px 20px 25px -5px rgba(16, 24, 40, 0.1),
    0px 8px 10px -6px rgba(16, 24, 40, 0.1);
  border: none;
  transition: all 0.2s ease-out;
  margin-right: 16px;
  margin-bottom: 16px;

  &.expanded {
    height: calc(100% - 32px);
    min-width: calc(100% - 32px);
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    height: 100%;
  }

  .offcanvas-header {
    padding: 24px 24px 16px;
    border-bottom: 1px solid $neutral-color-200;

    h5 {
      font-size: 16px;
      line-height: 24px;

      @include media-breakpoint-up(lg) {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.72px;
      }
    }

    .btn-close {
      display: none;
    }

    .offcanvas-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .offcanvas-body {
    padding: 0px;
    border-radius: 24px;
  }
}

.file-upload-divider {
  height: 12px;
  width: 1px;
  background-color: $neutral-color-200;
}
