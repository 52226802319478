//
// breadcrumb.scss
//

// Breadcrumb item arrow

.breadcrumb {
  margin-bottom: 0px !important;
}

.breadcrumb-item {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  color: $neutral-color-400;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    cursor: pointer;
    transition: color 0.6s ease-out;
    color: $neutral-color-400;

    &:hover {
      color: $primary-color-500;
    }
  }

  + .breadcrumb-item {
    &::before {
      font-weight: 500;
    }
  }

  &.active {
    > a {
      pointer-events: none;
      cursor: default;
      color: $primary-color-500;
    }
  }

  &.disabled {
    cursor: default;
  }
}
