@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.select-content-modal-wrapper {
  .select-content-modal-section {
    border-radius: 8px;
  }

  .area-wrapper {
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid $neutral-color-200;
    background: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .area-input {
    border: none;
    resize: none;

    &:focus,
    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      color: $neutral-color-400;
    }
  }

  button {
    svg {
      width: 20px;
    }
  }

  .select {
    &__control {
      border-color: $neutral-color-200;
      border-radius: 8px;
      padding: 10px 14px;

      &:hover,
      &:active,
      &:focus {
        border-color: $neutral-color-200;
        box-shadow: none;
      }

      &--is-focused {
        border-color: $neutral-color-200 !important; // Add !important to override default focus styles
        box-shadow: none !important; //
      }
    }

    &__dropdown-indicator {
      display: none;
    }

    &__clear-indicator {
      padding: 0;
      cursor: pointer;
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding: 0;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__placeholder {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: $neutral-color-400;
    }

    &__option {
      padding: 8px 16px;

      &:hover,
      &:active {
        background-color: $primary-color-50;
      }

      &--is-selected,
      &--is-focused {
        background-color: $primary-color-50;
      }
    }

    &__input-container {
      padding: 0;
      margin: 0;
    }
  }

  .divider {
    height: 1px;
  }

  .submit-btn {
    height: 36px;
    width: 36px;
    padding: 10px;
  }

  .back-btn {
    color: $neutral-color-500;

    &:hover {
      color: $primary;
    }
  }
}
