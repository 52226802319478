@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.content-documentation-tool {
  table {
    box-shadow: 0px 1px 2px 0px $neutral-color-200;
    border-radius: 8px;

    thead {
      tr {
        th {
          &:first-child {
            border-top-left-radius: 8px;
          }
          &:last-child {
            border-top-right-radius: 8px;
          }
        }
      }
    }

    tbody {
      tr:last-child {
        border: none;
      }
    }

    th,
    td {
      padding: 0.75rem 1rem;
    }

    tr {
      border-bottom: 1px solid $neutral-color-200;
    }

    thead {
      background-color: $neutral-color-50;
    }

    .label-answer {
      width: 80%;
    }

    .learner-answer {
      width: 20%;
      text-align: center;
    }
  }

  .answer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .helper {
    display: flex;
    align-items: center;
    .helper-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin-right: 0.5rem;
      svg {
        width: 16px;
      }
    }
  }
}
