@mixin bg-mobile-hover {
  @include media-breakpoint-down(md) {
    &:hover {
      background-color: white !important;
    }
  }
}

@mixin evokio-text-truncate($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
