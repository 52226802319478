@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.arrow-icon {
  border-radius: 6px;
}

.help-tip-item {
  background-color: $neutral-color-50;
  border-radius: 12px;
  padding: 16px 12px;
  height: 100px;
  overflow: hidden;
}

.tip-icon {
  background-color: $primary-color-50;
  border-radius: 14px;
  padding: 12px;
}

.arrow-icon {
  position: absolute;
  z-index: 100;
  transform: translateY(-50%);
  top: 50%;
}

.arrow-icon-left {
  left: 12px;
}

.arrow-icon-right {
  right: 12px;
}
