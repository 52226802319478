@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';

.change-pw {
  &-form {
    &--input {
      input {
        height: 40px !important;
      }
    }
  }
}
