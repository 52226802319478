@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.card {
  &-element {
    width: 325px;

    .default {
      color: $primary-color-600;
    }

    @include media-breakpoint-up(md) {
      width: 380px;
    }
  }

  &-digit {
    color: $white;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.48px;
  }

  &-action-badge {
    padding: 2px 10px;
    border-radius: 24px;
    background-color: $primary-color-50;

    font-size: 12px;
    font-weight: 500;
    line-height: 16px;

    text-wrap: nowrap;

    &:hover {
      background-color: $neutral-color-300;
    }
  }
}
