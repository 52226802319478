@import '~bootstrap/scss/functions';
@import 'assets/scss/variables';
@import '~bootstrap/scss/mixins';

.welcome-section {
  .option {
    padding: 20px 24px;
    border-radius: 16px;
    background: $primary-color-50;
    .title {
      color: $primary-color-400;
    }

    .description {
      color: $primary-color-500;
    }

    @include media-breakpoint-up(lg) {
      background: $neutral-color-50;

      .title {
        color: $neutral-color-400;
      }

      .description {
        color: $neutral-color-500;
      }

      &:hover {
        background-color: $primary-color-50;
        box-shadow:
          0px 1px 3px 0px rgba(16, 24, 40, 0.1),
          0px 1px 2px -1px rgba(16, 24, 40, 0.1);

        .icon {
          background-color: $primary-color-100;
        }

        .title {
          color: $primary-color-400;
        }

        .description {
          color: $primary-color-500;
        }
      }
    }

    .icon {
      height: 84px;
      width: 84px;
      border-radius: 50%;
      background-color: $neutral-color-200;
      border: 1px solid $primary-color-50;
    }
  }

  .assistant {
    .guide {
      width: fit-content;
      border-radius: 12px;
      padding: 12px;
    }
  }
}
